import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    CoreHeader,
    FontVariant,
    FullSizeWindow,
    PexipLogo,
    Text,
    TextHeading,
    ThemeProvider,
} from '@pexip/components';

import {ColorPaletteGenerator} from '../viewModels/ColorPaletteGenerator.viewModel';
import {BrandDownloader} from '../viewModels/BrandDownloader.viewModel';
import {BrandPreview} from '../viewModels/BrandPreview.viewModel';
import {LogoUploader} from '../viewModels/LogoUploader.viewModel';
import {BackgroundUploader} from '../viewModels/BackgroundUploader.viewModel';
import {JumbotronUploader} from '../viewModels/JumbotronUploader';
import {BrandNameInput} from '../viewModels/BrandNameInput.viewModel';
import {BrandTitleInput} from '../viewModels/BrandTitleInput.viewModel';
import {BackgroundColorHandler} from '../viewModels/BackgroundColorHandler.viewModel';
import {Sidebar} from '../views/Sidebar/Sidebar.view';
import {BuilderGrid} from '../views/BuilderGrid/BuilderGrid.view';
import {TermsAndConditionsLinkInput} from '../viewModels/TermsAndConditionsLinkInput.viewModel';
import {Overlay} from '../viewModels/Overlay.viewModel';
import {OverlayOpacitySlider} from '../viewModels/OverlayOpacitySlider.viewModel';
import {DisconnectDestinationLinkInput} from '../viewModels/DisconnectDestinationLinkInput.viewModel';
import {HandleOauthRedirectsToggle} from '../viewModels/HandleOauthRedirectsToggle.viewModel';

export const Branding: React.FC = () => {
    const {t} = useTranslation();
    return (
        <ThemeProvider colorScheme="light">
            <FullSizeWindow
                background="light"
                flexDirection="column"
                padding="none"
            >
                <BuilderGrid
                    header={
                        <CoreHeader
                            enhancerStart={
                                <PexipLogo
                                    className="ml-4"
                                    aria-label={t(
                                        'common.label-pexip-logo',
                                        'Pexip logo',
                                    )}
                                    size="medium"
                                />
                            }
                            enhancerEnd={
                                <BrandDownloader>
                                    <Trans t={t} key="download">
                                        Download
                                    </Trans>
                                </BrandDownloader>
                            }
                            hasBottomBorder={true}
                        />
                    }
                    aside={
                        <Sidebar>
                            <TextHeading
                                className="mt-9"
                                htmlTag="h1"
                                fontVariant={FontVariant.H5}
                            >
                                <Trans t={t} key="branding.welcome-to">
                                    Welcome to
                                </Trans>
                            </TextHeading>
                            <BrandNameInput />
                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.logo">
                                    Logo
                                </Trans>
                            </Text>
                            <LogoUploader />
                            <Text
                                htmlTag="p"
                                fontVariant={FontVariant.H5}
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.brand-color">
                                    Brand color
                                </Trans>
                            </Text>
                            <ColorPaletteGenerator />
                            <Text
                                htmlTag="p"
                                fontVariant={FontVariant.H5}
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.background-color">
                                    Background color
                                </Trans>
                            </Text>
                            <BackgroundColorHandler />
                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.jumbotron">
                                    Card image
                                </Trans>
                            </Text>
                            <JumbotronUploader />
                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.background-image">
                                    Background image
                                </Trans>
                            </Text>
                            <BackgroundUploader />

                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.overlay">
                                    Overlay
                                </Trans>
                            </Text>
                            <Overlay />

                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9"
                            >
                                <Trans t={t} key="branding.overlay-opacity">
                                    Overlay opacity
                                </Trans>
                            </Text>
                            <OverlayOpacitySlider />

                            <TextHeading
                                className="mt-9"
                                htmlTag="h1"
                                fontVariant={FontVariant.H5}
                            >
                                <Trans t={t} key="branding.application-title">
                                    Application title
                                </Trans>
                            </TextHeading>
                            <BrandTitleInput />
                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9 mb-0"
                            >
                                <Trans t={t} key="branding.terms-of-service">
                                    Terms of service
                                </Trans>
                            </Text>
                            <Text
                                fontVariant={FontVariant.Body}
                                htmlTag="p"
                                variant="tinted"
                            >
                                <Trans
                                    t={t}
                                    key="branding.terms-of-service-description"
                                >
                                    Adds text with links to your terms of
                                    service or privacy policy
                                </Trans>
                            </Text>

                            <TermsAndConditionsLinkInput />

                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9 mb-0"
                            >
                                <Trans
                                    t={t}
                                    key="branding.disconnect-destination"
                                >
                                    Disconnect destination
                                </Trans>
                            </Text>
                            <Text
                                fontVariant={FontVariant.Body}
                                htmlTag="p"
                                variant="tinted"
                            >
                                <Trans
                                    t={t}
                                    key="branding.disconnect-destination-description"
                                >
                                    URL destination to send participants to upon
                                    disconnect from a meeting as an alternative
                                    to return them to the post meeting page.
                                </Trans>
                            </Text>

                            <DisconnectDestinationLinkInput />

                            <Text
                                fontVariant={FontVariant.H5}
                                htmlTag="p"
                                className="mt-9 mb-0"
                            >
                                <Trans
                                    t={t}
                                    key="branding.handle-oauth-redirects"
                                >
                                    Handle OAuth 2.0 Redirects
                                </Trans>
                            </Text>
                            <Text
                                fontVariant={FontVariant.Body}
                                htmlTag="p"
                                variant="tinted"
                            >
                                <Trans
                                    t={t}
                                    key="branding.handle-oauth-redirects-description"
                                >
                                    Enables the OAuth 2.0 redirect handler on
                                    the /webapp3/oauth-redirect path.
                                </Trans>
                            </Text>

                            <HandleOauthRedirectsToggle />
                            <div className="mt-9" />
                        </Sidebar>
                    }
                >
                    <BrandPreview />
                </BuilderGrid>
            </FullSizeWindow>
        </ThemeProvider>
    );
};
