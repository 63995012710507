import React, {useState} from 'react';

import {Input} from '@pexip/components';

import {isValidURL} from '../utils/isValidUrl';
import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';
import {TestId} from '../../test/testIds';

export const DisconnectDestinationLinkInput = () => {
    const [hasError, setHasError] = useState(false);
    const [value, setValue] = useState(
        useManifestKey('applicationConfig').disconnectDestination ?? '',
    );

    const dispatch = useBrandDispatch();
    const handleDisconnectDestinationLink = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const link = e.target.value;
        if (link.length > 0) {
            setHasError(!isValidURL(value));
            dispatch({
                type: 'SET_DISCONNECT_DESTINATION',
                disconnectDestination: link,
            });
        }
    };

    return (
        <Input
            testId={TestId.DisconnectDestinationLink}
            placeholder="https://example.com/disconnected"
            label=""
            name="disconnectDestination"
            colorScheme="light"
            value={value}
            onValueChange={setValue}
            errorText={hasError ? 'Invalid URL' : ''}
            hasError={hasError}
            type="url"
            onBlur={handleDisconnectDestinationLink}
            onFocus={() => setHasError(false)}
        />
    );
};
