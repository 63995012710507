import React from 'react';

import {BrandProvider} from './contexts/Brand.context';
import {Branding} from './pages/Branding.page';
import {Home} from './pages/Home.page';
import {createRoutesHook, useRouter} from './router';

const useRoutes = createRoutesHook([
    {
        exact: true,
        path: '/',
        node: Home,
    },
    {
        path: '/brand',
        node: Branding,
    },
]);

export const App: React.FC = () => {
    useRouter();

    return <BrandProvider>{useRoutes()}</BrandProvider>;
};
