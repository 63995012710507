import {BRAND_PATH} from '../constants';

export const getPath = (initialPath: string, brandingPath = BRAND_PATH) => {
    return (
        brandingPath +
        (initialPath.startsWith('/') || initialPath.startsWith('./')
            ? initialPath.split('/').slice(1).join()
            : initialPath)
    );
};
