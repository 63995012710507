import React, {useId, useRef, useState} from 'react';
import cx from 'classnames';

import {Text} from '../../elements/Text/Text';

import {ColorPreviewButton} from './PreviewButtons.view';

import styles from './ColorPickerPreviewButton.module.scss';

// matches '#rrggbb'
const hexColorPattern = new RegExp(/^#(?:[a-fA-F0-9]{2}){3}$/);

export const ColorPickerPreviewButton: React.FC<
    Omit<React.ComponentProps<'input'>, 'onChange' | 'type'> & {
        defaultValue?: string;
        label: string;
        onChange: (value: string) => void;
    }
> = ({defaultValue, label, onChange, ...props}) => {
    if (defaultValue && !hexColorPattern.test(defaultValue)) {
        throw new Error('Default color is limited to #rrggbb currently');
    }

    const id = useId();
    const ref = useRef<HTMLInputElement>(null);
    const [backgroundColor, setBackgroundColor] = useState(
        defaultValue ?? 'transparent',
    );

    const openInput = () => ref.current?.click();
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = evt => {
        onChange(evt.target.value);
    };

    return (
        <div className={styles.container}>
            <label htmlFor={id}>
                <Text className={cx(styles.label, styles.hidden)}>{label}</Text>
            </label>
            <input
                {...props}
                ref={ref}
                className={styles.input}
                id={id}
                type="color"
                value={backgroundColor}
                onChange={e => {
                    setBackgroundColor(e.target.value);
                    handleChange(e);
                }}
            />
            <ColorPreviewButton
                className={styles.button}
                htmlTag="div"
                onClick={openInput}
                colors={[{id: id, color: backgroundColor}]}
            ></ColorPreviewButton>
        </div>
    );
};
