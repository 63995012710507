import type {Manifest} from './types';

export const SHADES = [
    50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
] as const;

export const BRAND_PATH = 'webapp3/branding/';
export const MANIFEST_PATH = BRAND_PATH + 'manifest.json';

export const DEFAULT_MANIFEST: Manifest = {
    version: 0,

    meta: {name: 'DEFAULT', brandVersion: 'n/a'},
    brandName: '',
    images: {},
    translations: {},
    applicationConfig: {},
    defaultUserConfig: {},
};

export const SUPPORTED_IMAGE_FORMATS = '.png,.jpg,.jpeg,.webp,.avif';
export const SUPPORTED_LOGO_IMAGE_FORMATS = `${SUPPORTED_IMAGE_FORMATS},.svg`;
