import React, {useEffect, useRef} from 'react';

import {InfoCard, InfoCardType} from '@pexip/media-components';
import {Wrapper, Grid, Cell, FullSizeWindow} from '@pexip/components';

import {ColorPalettePreview} from '../views/ColorPalette/ColorPalettePreview.view';
import {useBrandState} from '../contexts/Brand.context';
import {useObjectURL} from '../hooks/useObjectURL';

export const BrandPreview: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);

    const {
        background,
        logo,
        jumbotron,
        manifest: {
            brandName,
            backgroundColor,
            colorPalette,
            overlay,
            overlayOpacity,
        },
    } = useBrandState();

    const backgroundImageSrc = useObjectURL(background);
    const logoUrl = useObjectURL(logo);
    const imageUrl = useObjectURL(jumbotron);

    const backgroundProps = backgroundImageSrc
        ? ({
              background: 'image',
              backgroundBlendMode: overlay === 'light' ? 'lighten' : 'darken',
              backgroundImageSrc,
              overlayOpacity,
              overlay,
          } as const)
        : ({background: 'dark'} as const);

    useEffect(() => {
        if (backgroundColor) {
            ref.current?.style.setProperty(
                '--color-brand-background',
                backgroundColor ?? '',
            );
        }
    }, [backgroundColor]);

    useEffect(() => {
        if (colorPalette) {
            colorPalette.forEach((color, i) => {
                ref.current?.style.setProperty(
                    `--color-brand-${i ? i * 100 : 50}`,
                    color,
                );
            });
        }
    }, [colorPalette]);

    return (
        <div style={{position: 'relative', aspectRatio: '16/9'}}>
            <FullSizeWindow
                flexDirection="column"
                padding="none"
                position="absolute"
                ref={ref}
                {...backgroundProps}
            >
                <Wrapper justifyContent="center">
                    <Grid style={{width: '100%'}}>
                        <Cell xs={1} />
                        <Cell xs={4}>
                            <InfoCard
                                logoUrl={logoUrl}
                                imageUrl={imageUrl}
                                brandName={brandName}
                                type={InfoCardType.Meeting}
                            ></InfoCard>
                        </Cell>
                        <Cell xs={6}>
                            <ColorPalettePreview />
                        </Cell>
                        <Cell xs={1} />
                    </Grid>
                </Wrapper>
            </FullSizeWindow>
        </div>
    );
};
