import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {Box, FontVariant, TextHeading, Text} from '@pexip/components';

import styles from './WelcomeBox.module.scss';

export const WelcomeBox: React.FC<
    React.PropsWithChildren<React.ComponentProps<'div'>>
> = ({className, children}) => {
    const {t} = useTranslation();

    return (
        <Box
            isWithShadow={true}
            padding="large"
            className={cx(className, styles.welcomeBox)}
        >
            <TextHeading
                className="mb-2"
                htmlTag={FontVariant.H2}
                style={{textAlign: 'center'}}
            >
                <Trans t={t} key="branding.home.welcome">
                    Welcome to
                    <br />
                    Pexip Brand Builder
                </Trans>
            </TextHeading>
            <Text htmlTag="p" fontVariant={FontVariant.H6}>
                {t(
                    'branding.home.description',
                    'Customize an existing brand or build a new one from scratch',
                )}
            </Text>
            <div className={styles.children}>{children}</div>
        </Box>
    );
};
