import React, {useState} from 'react';

import {Input} from '@pexip/components';

import {isValidURL} from '../utils/isValidUrl';
import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';
import {TestId} from '../../test/testIds';

export const TermsAndConditionsLinkInput = () => {
    const [hasError, setHasError] = useState(false);
    const [value, setValue] = useState(
        useManifestKey('applicationConfig').termsAndConditions?.en ?? '',
    );

    const dispatch = useBrandDispatch();
    const handleTermsAndConditionsLink = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const link = e.target.value;
        if (link.length > 0) {
            setHasError(!isValidURL(value));
            dispatch({
                type: 'SET_TERMS_AND_CONDITIONS',
                termsAndConditions: link,
            });
        }
    };

    return (
        <Input
            testId={TestId.TermsConditionsLink}
            placeholder="https://example.com/privacypolicy"
            label=""
            name="termsAndConditionsLink"
            colorScheme="light"
            value={value}
            onValueChange={setValue}
            errorText={hasError ? 'Invalid URL' : ''}
            hasError={hasError}
            type="url"
            onBlur={handleTermsAndConditionsLink}
            onFocus={() => setHasError(false)}
        />
    );
};
