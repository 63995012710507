import React, {useState} from 'react';

import {type ColorScheme, RadioButton, ThemeConsumer} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const Overlay: React.FC = () => {
    const [overlay, setOverlay] = useState<ColorScheme>(
        useManifestKey('overlay') ?? 'dark',
    );
    const dispatch = useBrandDispatch();

    const handleClick = (overlay: ColorScheme) => {
        setOverlay(overlay);
        dispatch({
            type: 'SET_OVERLAY',
            overlay: overlay,
        });
    };

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <>
                    <RadioButton
                        className="mr-8"
                        colorScheme={defaultColorScheme}
                        value={overlay}
                        label="Light"
                        checked={overlay === 'light'}
                        onClick={() => handleClick('light')}
                    />
                    <RadioButton
                        colorScheme={defaultColorScheme}
                        className="mr-8"
                        value={overlay}
                        label="Dark"
                        checked={overlay === 'dark'}
                        onClick={() => handleClick('dark')}
                    />
                </>
            )}
        </ThemeConsumer>
    );
};
