import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {
    Button,
    CoreHeader,
    DragAndUploadFiles,
    FullSizeWindow,
    PexipLogo,
    ThemeProvider,
} from '@pexip/components';

import {WelcomeBox} from '../views/WelcomeBox/WelcomeBox.view';
import {getImageAsset} from '../utils/handleFile';
import {loadBrand} from '../utils/loadBrand';
import {logger} from '../logger';
import {useBrandDispatch} from '../contexts/Brand.context';
import {router} from '../router';

export const Home: React.FC = () => {
    const {t} = useTranslation();

    const ref = useRef<HTMLInputElement>(null);

    const dispatch = useBrandDispatch();

    const handleLoadBrand = (blob?: Blob) =>
        loadBrand(blob)
            .then(brand => {
                dispatch({type: 'SET_BRAND', brand});
                router.history.push('/brand');
            })
            .catch((error: unknown) => {
                logger.error({error}, `Can't read brand package`);
            });

    return (
        <>
            <CoreHeader
                enhancerStart={
                    <PexipLogo
                        aria-label={t('common.label-pexip-logo', 'Pexip logo')}
                        colorScheme="light"
                        size="medium"
                        className="ml-4"
                    />
                }
                hasBottomBorder={false}
                style={{zIndex: '1'}}
            />
            <FullSizeWindow
                background="light"
                flexDirection="column"
                justifyContent="center"
                padding="none"
            >
                <ThemeProvider colorScheme="light">
                    <WelcomeBox>
                        <Button
                            className="mb-4"
                            modifier="fullWidth"
                            onClick={() => router.history.push('/brand')}
                        >
                            New brand
                        </Button>
                        <DragAndUploadFiles
                            accept=".zip,"
                            onChange={({currentTarget: {files}}) =>
                                handleLoadBrand(getImageAsset(files).blob)
                            }
                            onDrop={(_e, files) =>
                                handleLoadBrand(getImageAsset(files).blob)
                            }
                            subTitle={t(
                                'uploader.drag-n-drop',
                                'or drag and drop',
                            )}
                            title={t(
                                'branding.home.load-brand',
                                'Load existing brand',
                            )}
                            ref={ref}
                        />
                    </WelcomeBox>
                </ThemeProvider>
            </FullSizeWindow>
        </>
    );
};
