import React from 'react';
import {useTranslation} from 'react-i18next';

import {Input} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const BrandNameInput = () => {
    const {t} = useTranslation();
    const brandName = useManifestKey('brandName');
    const dispatch = useBrandDispatch();

    const handleBrandName = (brandName: string) =>
        dispatch({type: 'SET_BRAND_NAME', brandName});

    return (
        <Input
            placeholder={t(
                'branding.name-input-placeholder',
                "Your organization's name",
            )}
            label=""
            name="brandName"
            colorScheme="light"
            value={brandName}
            onValueChange={handleBrandName}
        />
    );
};
