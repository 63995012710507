import JSZip from 'jszip';
import React from 'react';

import {Button} from '@pexip/components';

import {MANIFEST_PATH} from '../constants';
import {useBrandState} from '../contexts/Brand.context';
import {getPath} from '../utils/getPath';

const downloadBrand = (pkg: Uint8Array, fileName = 'brand.zip') => {
    const blobUrl = window.URL.createObjectURL(
        new Blob([pkg], {type: 'text/plain'}),
    );
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    a.click();

    // Wait half a second, as reportedly some browsers will fail if it's revoked too quickly
    setTimeout(() => URL.revokeObjectURL(blobUrl), 500);
};

export const BrandDownloader: React.FC<React.PropsWithChildren> = ({
    children,
    ...props
}) => {
    const {manifest, background, logo, jumbotron} = useBrandState();

    const handleSave = async () => {
        const zip = new JSZip();

        if (manifest) {
            zip.file(MANIFEST_PATH, JSON.stringify(manifest));
        }

        if (
            background &&
            manifest &&
            typeof manifest.images.background === 'string'
        ) {
            zip.file(getPath(manifest.images.background), background);
        }

        if (logo && manifest && typeof manifest.images.logo === 'string') {
            zip.file(getPath(manifest.images.logo), logo);
        }

        if (
            jumbotron &&
            manifest &&
            typeof manifest.images.jumbotron === 'string'
        ) {
            zip.file(getPath(manifest.images.jumbotron), jumbotron);
        }

        const pkg = await zip.generateAsync({type: 'uint8array'});

        downloadBrand(pkg);
    };

    return (
        <Button size="large" className="mr-4" onClick={handleSave} {...props}>
            {children}
        </Button>
    );
};
