import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Bar,
    Box,
    Button,
    CenterLayout,
    FontVariant,
    IconTypes,
    Select,
    Text,
    Avatar,
    AvatarType,
    VideoWrapper,
    withColorScheme,
} from '@pexip/components';
import {nthThrows} from '@pexip/utils';

import styles from './ColorPalette.module.scss';

const audioInputs = [
    {
        label: 'Logitech BRIO 4k',
        id: '0',
    },
    {label: 'FaceTime HD Camera', id: '1'},
];

const BoxLight = withColorScheme(Box, 'light');
const BoxDark = withColorScheme(Box, 'dark');

export const ColorPalettePreview = () => {
    const {t} = useTranslation();
    const [value, setValueId] = useState(nthThrows(audioInputs, 0).id);
    return (
        <BoxLight padding="small" className={styles.demo}>
            <div className="text-center">
                <Text fontVariant={FontVariant.H3}>
                    {t('camera.can-you-see-yourself', 'Can you see yourself?')}
                </Text>
            </div>
            <BoxDark className="mt-2">
                <VideoWrapper background="primary" size="full">
                    <CenterLayout style={{aspectRatio: '16/9'}}>
                        <Avatar
                            type={AvatarType.Initials}
                            displayName={'Sam Junior'}
                            sizeModifier="medium"
                        />
                    </CenterLayout>
                </VideoWrapper>
            </BoxDark>
            <Select
                className="mt-2"
                isFullWidth
                value={value}
                onValueChange={o => setValueId(o)}
                options={audioInputs}
                iconType={IconTypes.IconVideoOn}
                labelModifier="hidden"
                label={t(
                    'settings.device-select.select-microphone',
                    'Select microphone',
                )}
            />
            <Bar className="mt-2">
                <Button
                    className="mr-1"
                    modifier="fullWidth"
                    variant="tertiary"
                >
                    {t('common.no', 'No')}
                </Button>
                <Button className="ml-1" modifier="fullWidth">
                    {t('common.yes', 'Yes')}
                </Button>
            </Bar>
            <Bar className="mt-2">
                <Button modifier="fullWidth" variant="secondary">
                    <Trans t={t} i18nKey="camera.cant-see">
                        I can&apos;t see myself due to a sight disability
                    </Trans>
                </Button>
            </Bar>
        </BoxLight>
    );
};
