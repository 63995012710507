import React from 'react';

import type {ButtonProps} from '../../elements/Button/Button';

import {AssetPreviewButton} from './PreviewButton.view';

interface Asset {
    id: string;
}

export interface ImageAsset extends Asset {
    src?: string;
}

export interface ColorAsset extends Asset {
    color?: string;
}

export const ImagePreviewButton: React.FC<
    React.PropsWithChildren<
        ButtonProps & {
            images?: ImageAsset[];
        }
    >
> = ({images, ...props}) => <AssetPreviewButton assets={images} {...props} />;

export const ColorPreviewButton: React.FC<
    React.PropsWithChildren<
        ButtonProps & {
            colors?: ColorAsset[];
        }
    >
> = ({colors, ...props}) => <AssetPreviewButton assets={colors} {...props} />;

export type ImagePreviewButtonProps = React.ComponentProps<
    typeof ImagePreviewButton
>;

export type ColorPreviewButtonProps = React.ComponentProps<
    typeof ColorPreviewButton
>;
