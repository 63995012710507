import React from 'react';
import cx from 'classnames';

import styles from './BuilderGrid.module.scss';

export const BuilderGrid: React.FC<
    {
        aside: React.ReactElement;
        header: React.ReactElement;
    } & React.PropsWithChildren<React.ComponentProps<'div'>>
> = ({className, children, aside, header, ...props}) => (
    <div className={styles.container}>
        <header>{header}</header>
        <div className={styles.content}>
            <aside>{aside}</aside>
            <main className={cx(styles.main, className)} {...props}>
                {children}
            </main>
        </div>
    </div>
);
