import {useEffect, useState} from 'react';

export const useObjectURL = (blob?: Blob) => {
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (!blob) {
            return;
        }

        const blobUrl = URL.createObjectURL(blob);
        setUrl(blobUrl);
        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [blob]);

    return url;
};
