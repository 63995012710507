import React from 'react';
import {useTranslation} from 'react-i18next';

import {Color, ColorPickerPreviewButton} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const BackgroundColorHandler: React.FC = () => {
    const {t} = useTranslation();
    const backgroundColor =
        useManifestKey('backgroundColor') ?? Color.DeepBlue100;
    const dispatch = useBrandDispatch();

    const handleBackgroundColor = (value: string) =>
        dispatch({type: 'SET_BACKGROUND_COLOR', backgroundColor: value});

    return (
        <ColorPickerPreviewButton
            defaultValue={backgroundColor}
            label={t('branding.background-color', 'Background color')}
            onChange={handleBackgroundColor}
        />
    );
};
