import React, {useState} from 'react';

import {RangeSlider, type OverlayOpacity} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const OverlayOpacitySlider: React.FC = () => {
    const [overlayOpacity, setOverlayOpacity] = useState(
        useManifestKey('overlayOpacity') ?? 0.7,
    );

    const dispatch = useBrandDispatch();

    const handleOverlayOpacity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value) as OverlayOpacity;
        setOverlayOpacity(value);
        dispatch({type: 'SET_OVERLAY_OPACITY', overlayOpacity: value});
    };

    return (
        <RangeSlider
            className="mb-5"
            max={useManifestKey('overlay') === 'light' ? 0.9 : 0.8}
            min={useManifestKey('overlay') === 'light' ? 0.6 : 0.5}
            step={0.1}
            selectedValue={overlayOpacity}
            onChange={handleOverlayOpacity}
        />
    );
};
