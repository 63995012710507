import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {DragAndUploadFiles, ThemeProvider} from '@pexip/components';

import {useBrandDispatch} from '../contexts/Brand.context';
import {getFileProps} from '../utils/handleFile';
import {SUPPORTED_LOGO_IMAGE_FORMATS} from '../constants';

export const LogoUploader: React.FC = () => {
    const {t} = useTranslation();

    const ref = useRef<HTMLInputElement>(null);
    const dispatch = useBrandDispatch();

    return (
        <ThemeProvider colorScheme="dark">
            <DragAndUploadFiles
                {...getFileProps(dispatch, 'SET_LOGO')}
                accept={SUPPORTED_LOGO_IMAGE_FORMATS}
                subTitle={t('uploader.drag-n-drop', 'or drag and drop')}
                title={t('uploader.add-logo', 'Add Logo Icon')}
                ref={ref}
            />
        </ThemeProvider>
    );
};
