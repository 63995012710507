import React, {useCallback, useEffect, useState} from 'react';
import convert from 'color-convert';
import type {HEX, HSL, RGB} from 'color-convert/conversions';
import {calculateColor} from '@skedify/color-utils';
import {useTranslation} from 'react-i18next';

import {Color, ColorPickerPreviewButton} from '@pexip/components';

import {SHADES} from '../constants';
import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const ColorPaletteGenerator: React.FC = () => {
    const {t} = useTranslation();
    const meta = useManifestKey('meta');
    const dispatch = useBrandDispatch();

    const [hex, setHexState] = useState(meta?.baseColor ?? Color.DeepBlue80);

    const setHex = useCallback((value: string) => {
        setHexState(value);
    }, []);

    useEffect(() => {
        const brandShades = calculateShades(calculateColor({brandHex: hex}));
        dispatch({type: 'SET_BASE_COLOR', baseColor: hex});

        const colorPalette = Object.values(brandShades).map(
            value => `#${convert.rgb.hex(value)}`,
        );
        dispatch({type: 'SET_COLOR_PALETTE', colorPalette});
    }, [hex, dispatch]);

    return (
        <ColorPickerPreviewButton
            defaultValue={hex}
            label={t(
                'branding.base-color',
                'Base brand color used to generate a color palette',
            )}
            onChange={setHex}
        />
    );
};

// borrowed from skedify/frontend-mono/apps/web-admin/src/utils/setupTailwindBrandColors.ts#L27
function calculateShades(
    brandHEX: HEX,
    shade = SHADES[Math.floor((SHADES.length - 1) / 2)],
) {
    const LIGHTEST_HSL = 95;
    const DARKEST_HSL = 10;
    const shadeIndex = SHADES.findIndex(s => s === shade);
    const brandHSL = convert.hex.hsl(brandHEX);
    const brandLightness = brandHSL[2];

    const [start, end] = getStartMiddleStop();

    function getStartMiddleStop() {
        if (shadeIndex === 0) {
            return [brandLightness, DARKEST_HSL] as const;
        }
        if (shadeIndex === SHADES.length + 1) {
            return [LIGHTEST_HSL, brandLightness] as const;
        }
        return [LIGHTEST_HSL, DARKEST_HSL] as const;
    }

    // find all lightness shades we'll use, starting with first
    const lightness = [start];

    // amount of steps between shadeIndex and lightness start/end
    const stepsLighter = shadeIndex; // shades before our color without start
    const stepsDarker = SHADES.length - shadeIndex - 1; // shades after our color without end

    // size of each step up or down
    const stepSizeLighter =
        stepsLighter !== 0 ? (LIGHTEST_HSL - brandHSL[2]) / stepsLighter : 0;
    const stepSizeDarker =
        stepsDarker !== 0 ? (brandHSL[2] - DARKEST_HSL) / stepsDarker : 0;

    // add the lighter shades after start
    for (let i = 1; i < stepsLighter; i++) {
        lightness[i] = (lightness[i - 1] as number) - stepSizeLighter;
    }

    // when start and end are not our brandHSL, add it here
    if (brandHSL[2] !== start && brandHSL[2] !== end) {
        lightness.push(brandHSL[2]);
    }

    // add shades on darker side of brand
    for (let i = 1; i < stepsDarker; i++) {
        const thisIndex = lightness.length;
        lightness[thisIndex] =
            (lightness[thisIndex - 1] as number) - stepSizeDarker;
    }

    // add the last shade
    lightness.push(end);

    return Object.fromEntries(
        SHADES.map((shade, idx) => {
            const hsl: HSL = [
                brandHSL[0],
                brandHSL[1],
                lightness[idx] as number,
            ];
            const rgb: RGB = convert.hsl.rgb(hsl);

            return [shade, rgb];
        }),
    );
}
