import JSZip from 'jszip';

import {DEFAULT_MANIFEST, MANIFEST_PATH} from '../constants';
import type {Manifest} from '../types';

import {getPath} from './getPath';

const BRAND_URL = new URL(`./brand.zip`, document.baseURI).toString();

const getLoadImages = (files: JSZip) => async (path: string) => {
    const backgroundData = await files.file(path)?.async('arraybuffer');

    if (!backgroundData) {
        return;
    }

    return new Blob(
        [backgroundData],
        path.endsWith('svg') ? {type: 'image/svg+xml'} : {},
    );
};

export const loadBrand = async (archive: string | Blob = BRAND_URL) => {
    const zip = new JSZip();
    const files = await zip.loadAsync(
        typeof archive === 'string'
            ? await (await fetch(archive)).blob()
            : archive,
    );
    const manifestData =
        (await files.file(MANIFEST_PATH)?.async('text')) ?? '{}';
    const brand: {
        manifest: Manifest;
        background?: Blob;
        logo?: Blob;
        jumbotron?: Blob;
    } = {manifest: DEFAULT_MANIFEST};
    const manifest = JSON.parse(manifestData) as Manifest;
    if (!manifest) {
        return brand;
    }

    brand.manifest = manifest;

    const loadImages = getLoadImages(files);

    // FIXME: support srcSet too
    if (typeof manifest.images.logo === 'string') {
        const logo = await loadImages(getPath(manifest.images.logo));
        if (logo) {
            brand.logo = logo;
        }
    }

    // FIXME: support BreakpointImageSet too
    if (typeof manifest.images.background === 'string') {
        const background = await loadImages(
            getPath(manifest.images.background),
        );
        if (background) {
            brand.background = background;
        }
    }

    if (manifest.images.jumbotron) {
        const jumbotron = await loadImages(getPath(manifest.images.jumbotron));
        if (jumbotron) {
            brand.jumbotron = jumbotron;
        }
    }

    return brand;
};
