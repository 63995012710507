import type {Dispatch} from 'react';

import type {Action, ImageActionTypes} from '../contexts/Brand.context';

export const getImageAsset = (files?: FileList | File[] | null) => {
    if (files?.length && files[0]) {
        return {name: `./${files[0].name}`, blob: files[0]};
    }

    throw new Error('No Image assets in the FileList');
};

const getHandleChangeFile =
    (dispatch: Dispatch<Action>, type: ImageActionTypes) =>
    ({currentTarget: {files}}: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({type, ...getImageAsset(files)});

const getHandleDropFile =
    (dispatch: Dispatch<Action>, type: ImageActionTypes) =>
    (_e: React.DragEvent<HTMLDivElement>, files?: File[]) =>
        dispatch({type, ...getImageAsset(files)});

export const getFileProps = (
    ...args: [dispatch: Dispatch<Action>, actionType: ImageActionTypes]
) => ({
    onDrop: getHandleDropFile(...args),
    onChange: getHandleChangeFile(...args),
});
