import React from 'react';
import cx from 'classnames';

import {Scrollbars} from '@pexip/components';

import styles from './Sidebar.module.scss';

export const Sidebar: React.FC<
    React.PropsWithChildren<React.ComponentProps<'div'>>
> = ({className, children, ...props}) => (
    <div className={cx(styles.wrapper, className)} {...props}>
        <Scrollbars className={styles.innerWrapper}>{children}</Scrollbars>
    </div>
);
