import React from 'react';
import {useTranslation} from 'react-i18next';

import {Input} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const BrandTitleInput = () => {
    const {t} = useTranslation();
    const appTitle = useManifestKey('appTitle');
    const dispatch = useBrandDispatch();

    const handleBrandTitle = (appTitle: string) =>
        dispatch({type: 'SET_APP_TITLE', appTitle});

    return (
        <Input
            placeholder={t(
                'branding.app-title-input-placeholder',
                "Your app's tab title",
            )}
            label=""
            name="brandTitle"
            colorScheme="light"
            value={appTitle}
            onValueChange={handleBrandTitle}
        />
    );
};
